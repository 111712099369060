#container-order {
    background-color: white;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 4px;
    padding: 20px;
}

#container-order label{
    font-weight: bold;
    color: #7e7e7e;
}

.containedDataGrid {
    margin-top: 16px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
}

#button-order {
    font-weight: bold;
}

.title-order {
    margin-bottom: 1rem;
    text-align: center;
}