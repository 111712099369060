.grid-login {
    align-items: center;
    justify-content: center;
    min-height: 70vh;
}

.login-form {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
    text-align: center;
    width: 100%;
    max-width: 400px;
    margin-bottom: 20px;
    padding: 10px;
}

#form-control-login label {
    font-weight: bold;
    color: #7e7e7e;
    font-size: 16px;
}

.logo-login {
    margin-top: 40px;
    margin-bottom: 40px;
    height: 160px;
    width: 280px;
}

#connexion-text {
    margin: 20px;
    font-family: Georgia, serif;
    font-weight: bold;
    color: darkgrey;
}

/* Styles pour rendre le formulaire responsive */
@media (max-width: 768px) {
    .login-form {
        max-width: 80%;
    }
}