.canvas {
  box-sizing: border-box;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card-product {
  width: 50vwh;
  background-color: rgba(243, 243, 243, 0.559);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  display: flex;
  margin: 20px;
}

.images {
  margin: 20px;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
}

.product-card-product .images img {
  height: 60%;
  overflow: hidden;
  width: 100%;
  object-fit: contain;
}

.form-container {
  flex: 1;
  justify-content: center;
  position: relative;
  padding: 30px 10px 10px 10px;
}

#form-control-product label {
  font-weight: bold;
  color: #7e7e7e;
}

.form-wrapper {
  margin-left: 10%;
  width: 80%;
  padding: auto;
}

.form-field {
  margin-bottom: 14px;
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  color: #7e7e7e;
  font-size: 16px;
  margin-bottom: 2px;
}

#button-product-back {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

#button-product {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .product-card-product {
    flex-direction: column;
    align-items: center;
    margin: 40px;
  }

  .images {
    margin: 12px;
  }

  .form-field {
    margin-top: 14px;
  }
}

.text-center-product {
  text-align: center;
  margin: 0 auto;
  margin-bottom: 10px;
}

.button-product-container {
  margin: 10px;
}

.icon-margin {
  margin-right: 5px;
}