/* styles.css */

.container-shipping {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 20px;
    margin-top: 40px;
    width: fit-content;
    height: fit-content;
}

.title-shipping {
    margin-bottom: 1rem;
    text-align: center;
}

.div-shipping{
    margin: 10px;
}

#button-shipping {
    margin-top: 40px;
    font-size: 24px;
    color: white;
}

#form-control-shipping{
    margin-top: 40px;
}

#form-control-shipping label{
    font-weight: bold;
    color: #7e7e7e;
}