/* styles.css */

.div-checkout {
    margin: 10px;
}

.title-checkout {
    margin-bottom: 1rem;
    text-align: center;
}

.container-checkout {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    padding: 20px;
    margin-top: 40px;
    width: fit-content;
    height: fit-content;
}

#form-control-checkout{
    margin-bottom: 10px;
}

#form-control-checkout label {
    font-weight: bold;
    color: #7e7e7e;
    font-size: 16px;
    margin-bottom: 2px;
}

#form-control-checkout textarea {
    min-height: 50px;
    border: 2px solid #abb1b4;
    border-radius: 4px;
    outline: none;
}

#form-control-checkout textarea:focus {
    border: 2px solid #0091EA;
    border-radius: 4px;
}

.grid-checkout {
    border: 1px solid #808080;
    border-radius: 5px;
    padding: 10px;
}

.image-checkout {
    width: 180px;
    height: 120px;
    margin-right: 10px
}

.addressContainer {
    display: flex;
    align-items: center;
    margin: 20px 0;
}

.hrStyle {
    flex: 1;
    height: 1px;
    background: black;
}

.deliveryAddress {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#button-checkout {
    font-size: 24px;
    color: white;
}

.deliveryInfo {
    margin: 0;
    margin-left: 20px;
}

.addressInfo {
    margin: 0;
    text-align: left;
    margin-right: 60px;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
    .container-checkout {
        width: 100%;
    }

    #form-control-checkout textarea {
        width: 100%;
    }

    .grid-checkout {
        padding: 5px;
    }

    #button-checkout {
        font-size: 16px;
        margin-bottom: 40px;
    }

    .product-checkout {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;
    }

    .image-checkout {
        margin-right: 0px;
    }

    .deliveryAddress {
        flex-direction: column;
    }

    .deliveryInfo,
    .addressInfo {
        text-align: center;
        margin-right: 0px;
        margin-bottom: 20px;
    }
}