.container {
  height: 85vh;
}

.title-shoppingcart {
  margin-bottom: 1rem;
  text-align: center;
}

.panier-vide-page {
  display: flex;
  flex: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.button-grid-container {
  align-items: center;
  position: sticky;
  margin-bottom: 20px !important;
}

.div-button {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  text-align: center;
  margin-top: 36px;
}

.center-content {
  align-items: center;
}

.grid-saved-cart {
  align-items: center;
}

.div-saved-cart {
  background-color: rgba(245, 245, 245, 0.5);
  border: 1px solid #808080;
  border-radius: 5px;
  margin-bottom: 20px;
  width: fit-content;
}

#big-button {
  margin-top: 10px;
  font-size: 24px;
  color: white;
}

#small-button {
  margin-top: 14px;
  font-size: 14px;
}

.Card {
  display: flex;
  margin-bottom: 20px;
}

.Card img {
  max-width: 300px;
  margin-right: 20px;
}

#long-text,
#long-text-saved {
  max-width: 344px;
  word-wrap: break-word;
}

.CardSaved {
  display: flex;
  margin: 20px;
}

.CardSaved img {
  max-width: 200px;
  margin-right: 20px;
}

.CardContent {
  display: flex;
  flex-direction: row;
}

.button-container {
  display: flex;
  margin-bottom: 15px;
  justify-content: space-between;
}

#custom-button {
  padding: 8px 12px;
  border-radius: 4px;
}

.SavedItems {
  width: 100%;
  margin-top: 20px;
}

.SavedItemButtons {
  margin-top: 10px;
}

@media only screen and (max-width: 1000px) {

  .button-grid-container {
    display: block;
    align-items: center;
    position: inherit;
  }

  .div-button {
    position: inherit;
    top: auto;
    text-align: center;
    margin: 34px;
  }

  .CardContent {
    flex-direction: column;
  }

  .Card img {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .CardSaved img {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .button-container {
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  #custom-button {
    margin: 1px;
  }

  #long-text {
    max-width: 278px;
  }

  #long-text-saved {
    max-width: 236px;
  }
}