.product-cards-home {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.button-container-home {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.addProduct-home {
    cursor: pointer;
}

.product-card-home {
    width: calc(30% - 48px);
    margin: 24px;
    padding: 15px;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);

    @media (max-width: 1200px) {
        width: calc(40% - 48px);
    }

    @media (max-width: 992px) {
        width: calc(45% - 48px);
    }

    @media (max-width: 768px) {
        width: calc(50% - 48px);
    }

    @media (max-width: 576px) {
        width: calc(100% - 48px);
    }

    .img-home {
        width: 100%;
        height: 280px;
        object-fit: contain;
        margin-bottom: 10px;
    }
}