/* _notfound.scss */

.not-found-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-notfound {
  background-color: rgba(255, 255, 255, 0.6); /* Fond blanc avec opacité de 0.5 */
  padding: 20px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  border-radius: 15px; /* Ajuste le rayon selon tes préférences */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Ajoute une ombre légère pour l'effet de profondeur */
  text-align: center; /* Centre le texte horizontalement */
}

.not-found-page h1 {
  font-size: 4rem;
  font-weight: bold;
}

.not-found-page p {
  font-size: 2rem;
  margin: 0 0 20px 0;
}

.not-found-page a {
  font-size: 2rem;
  color: #000;
  text-decoration: none;
}

/* Styles pour rendre la page responsive */
@media screen and (max-width: 600px) {
  .not-found-page h1 {
    font-size: 3rem;
  }

  .not-found-page p {
    font-size: 1.5rem;
  }

  .not-found-page a {
    font-size: 1.5rem;
  }
}