body {
  position: relative;
  background: radial-gradient(circle, rgba(203,220,228,1) 0%, rgba(199,216,224,1) 50%, rgba(61,97,119,1) 100%);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  justify-content: center;
  align-items: center;
}

label{
  font-family: "Nunito Sans", sans-serif;
}

div{
  font-family: "Nunito Sans", sans-serif;
}