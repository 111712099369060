#container-savedshoppingcart {
    text-align: center;
    margin-bottom: 40px;
}

.title-savedshoppingcart {
    margin-bottom: 1rem;
    text-align: center;
}

.Grid-savedshoppingcart {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Card-savedshoppingcart {
    display: flex;
    width: fit-content;
    margin: 5px;
}

.Card-savedshoppingcart img {
    max-width: 300px;
    box-shadow: 0 0 3px rgba(0, 0, 0, .25);
}

.CardContent-savedshoppingcart {
    display: flex;
    flex-direction: column;
}

.text-savedshoppingcart {
    margin-top: 10px;
}

#title-savedshoppingcart {
    font-weight: bold;
}

.button-savedshoppingcart {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

#cardButton-savedshoppingcart {
    margin-bottom: 10px;
}