.mentions-legales {
    background-color: white;
    border-radius: 10px;
    margin-top: 40px;

    h1 {
        font-size: 2em;
        margin-bottom: 20px;
    }

    .section {
        margin-bottom: 40px;

        h2 {
            font-size: 1.5em;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 10px;
        }
    }
}