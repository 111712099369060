#container-orderdetails {
    padding: 20px;
    background-color: white;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 6px;
}

.first-grid-orderdetails {
    padding: 20px;
    background-color: rgb(240, 240, 240);
    border: 1px solid rgb(215, 215, 215);
    border-radius: 6px;
}

.image-grid-orderdetails {
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-orderdetails {
    box-shadow: 0 0 3px rgba(0, 0, 0, .25);
    margin: 20px;
    height: 220px;
    width: 320px;
}

.grid-section {
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #e3e3e3;
    background-image: linear-gradient(to bottom, #e8e8e8 0, #f5f5f5 100%);
}

.multiline-email {
    overflow-wrap: break-word;
}

.value-right {
    float: right;
}

#title-orderdetails {
    margin-top: 20px;
  margin-bottom: 0; /* Ajoutez cette ligne pour éviter une marge en bas par défaut */
  text-align: center;
  }

@media (max-width: 700px) {
    .hide-hr hr {
        display: none;
    }
}