.title-user {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
}

#container-user {
    background-color: white;
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 4px;
    padding: 20px;
}

.button-container-user {
    text-align: right;
}

.form-field-user {
    display: flex;
    flex-direction: column;
}

#form-control-user label {
    font-weight: bold;
    color: #7e7e7e;
}

.password-conditions {
    margin-top: 20px;
}